import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Class = () => (
  <Layout>
    <SEO title="Class" />
    <p>
        I'd suggest choosing the class that you think you would enjoy playing as the most! Just like with race, the best way that you can improve at the game is to enjoy playing it and log in frequently. Playing a class you don't enjoy will only hold you back even if its popular at the time.
    </p>
    <p>
        Having said that, here are some suggestions if you're not sure about what you think you will enjoy in the arena.
    </p>
    <ul style={{listStyle: `none`,}}>
        <li>
            <span style={{color: `#C79C6E`,}}>Warrior</span>: Big sword, big damage. Do you like smashing things? Comes with lots of utility to help your team out as well!
        </li>
        <li>
            <span style={{color: `#F58CBA`,}}>Paladin</span>: Big sword, big damage + you can read books and heal and stuff. Paladins can be fun because they have access to all three roles: healing, damage, and tanking. They are typically more straight forward and have a slower playstyle.
        </li>
        <li>
            <span style={{color: `#ABD473`,}}>Hunter</span>: This class can either be really simple or really complicated depending on your specialization. It can be a great choice if you're really new and want to learn the basics of the arena quicker without having to deep dive into the intricacies of your own class right away. (Beast Mastery is the simple spec)
        </li>
        <li>
            <span style={{color: `#FFF569`,}}>Rogue</span>: Typically a more advanced class to play. You're sneaky, you've got high APM, and you're all about controlling other players. Typically the playstyle is more focused around setting up "goes" or situations where you do all of your damage in a short window while crowd-controling the enemy players and then running away until you can set it up again. You're BFFs with mages.
        </li>
        <li>
            <span style={{ WebkitTextFillColor: `#FFFFFF`, WebkitTextStrokeWidth: `1px`, WebkitTextStrokeColor: `#a9a9a9`,}}>Priest</span>: The only class in the game with two healing specializations. You're probably picking this to heal but their shadow specialization can be really fun and powerful for more advanced players. One of their healing specs is more traditional and the other is pretty unique - It's more aggressive and built around dealing damage with a focus on absorb shields.
        </li>
        <li>
            <span style={{color: `#0070DE`,}}>Shaman</span>: You can turn into a wolf and you have access to playing as a healer, a ranged damage dealer, and a melee damage dealer. Nice for variety! A lot of your toolkit is built around doing things before other things happen instead of reacting to them afterwards. Because of this, it can be tricky to learn if its your first time in the arena and you don't know how other classes operate yet.
        </li>
        <li>
            <span style={{color: `#69CCF0`,}}>Mage</span>: Pick this if you like to cast spells and be smart and think you're better than everyone else (espeically warriors). Probably the most simple of the wizards.
        </li>
        <li>
            <span style={{color: `#9482C9`,}}>Warlock</span>: Pick this if you like to cast spells and summon demons and look cool. You probably also think you're better than anyone who engages in melee combat (peasants). Probably the more complicated of the wizards.
        </li>
        <li>
            <span style={{color: `#00FF96`,}}>Monk</span>: High mobility, really fun. Pick this if you like to punch stuff a lot or do monk things. Can heal with mysterious misty powers and is probably the most simple of all the healers.
        </li>
        <li>
            <span style={{color: `#FF7D0A`,}}>Druid</span>: Can transform into a bunch of animals. One with nature. Really fast. The only class in the game with four specializations. Lots of variety. Can sometimes be complicated / demand higher APM and quicker reaction time.
        </li>
        <li>
            <span style={{color: `#A330C9`,}}>Demon Hunter</span>: Edgy af, big dam, insane speed. One of the more (if not the most) simple classes having only two specializations to choose from and realistically you're only going to ever be using one in arena.
        </li>
        <li>
            <span style={{color: `#C41F3B`,}}>Death Knight</span>: The original hero class. Big sword, big damage, necromancer class fantasy. Similar to the beast mastery hunter (except you're melee this time) where you have access to a specialization thats simpler than most (frost), allowing you to focus on the greater arena mechanics instead of your class.
        </li>
    </ul>

  </Layout>
)

export default Class
